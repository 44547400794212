.modal-window {
  position: fixed;
  background-color: rgba(200, 200, 200, 0.75);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-window:target {
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 320px;
  position: relative;
  margin: 5% auto;
  padding: 25px;
  border-radius: 27px;
  background: #fff;
  color: #444;
}

.airtable-embed {
  background: transparent;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iframe-container {
  min-height: 100px;
  background: url("/spinner.gif") no-repeat 50% top !important;
}
