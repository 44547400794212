ul {
  margin: 10px;
  list-style-type: none;
  padding-left: 0;
  width: 100%;
  height: 100%;
}

li {
  padding: 12px 12px;
  border-bottom: 1px solid black;
  cursor: pointer;
  transition: all 1s;
}

li:hover {
  background: pink;
  padding-left: 24px;
  padding-right: 24px;
  /* letter-spacing: 1px; */
}

h2 {
  margin-bottom: 0;
}

.eventDateContainer {
  text-align: center;
  padding: 0px 15px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  background: black;
  color: white;
}

.infoContainer {
  padding: 0px 10px 10px 10px;
  height: 95px;
}

.eventMonth {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
}

.eventDay {
  font-size: 72px;
  font-weight: bold;
}

.view {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.wrapper {
  flex: 1;
}

@media screen and (max-width: 480px) {
  .eventDay {
    padding-bottom: 25px;
  }

  h2 {
    text-align: left;
  }
}
