.eventInfoContainer {
  padding: 15px;
  background: black;
  color: white;

  display: flex;
  flex-direction: row;
}

.filler {
  flex: 1;
}

.dateContainer {
  text-align: center;
  padding: 0px 15px;
  line-height: 60px;
  display: flex;
  flex-direction: column;
}

.month {
  font-size: 50px;
  font-weight: bold;
  text-transform: uppercase;
}

.day {
  font-size: 106px;
  font-weight: bold;
}

.informationContainer {
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.column {
  margin-right: 10px;
  font-size: 22px;
}

@media screen and (max-width: 480px) {
  .eventInfoContainer {
    padding: 0px;
  }

  .dateContainer {
    padding: 10px 10px;
    line-height: 40px;
  }

  .informationContainer {
    padding: 12px 0px;
  }

  .filler {
    flex: 1;
  }

  .month {
    font-size: 30px;
  }

  .day {
    font-size: 50px;
  }
}
