.button {
  background-color: black;
  border: 2px solid black;
  border-radius: 27px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px;
  text-decoration: none;
  cursor: pointer;
}

.heart::after {
  margin-left: 10px;
  font-family: fontAwesome;
  content: "\f004\00a0";
}

/* Grow Rotate */
.hvr-grow-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow-rotate:hover,
.hvr-grow-rotate:focus,
.hvr-grow-rotate:active {
  -webkit-transform: scale(1.1) rotate(4deg);
  transform: scale(1.1) rotate(4deg);
}

@media screen and (max-width: 480px) {
  button {
    margin-top: 20px;
    margin-left: 0px;
  }
}
