.header {
  padding: 15px 50px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.invitation {
  max-width: 900px;
}

h1 {
  font-size: 106px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: black;
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 56px;
    padding: 15px 50px;
    -webkit-text-stroke-width: 3px;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .signup {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  button {
    margin-top: 20px;
    margin-left: 0px;
  }
}

h2 {
  color: black;
  font-size: 36px;
  text-align: center;
  margin-top: 0px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

h3 {
  font-size: 24px;
  text-align: center;
}

.eventDescriptionContainer {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px 80px;
  border: 10px dotted black;
}

p {
  font-size: 22px;
}

.signup {
  font-size: 36px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  height: 200px;
  padding: 50px 15px;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.completedContainer {
  padding: 15px 0px 50px 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.completedPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.icon {
  font-size: 96px;
}

.map {
  margin-top: 20px;
}
